<script setup lang="ts">
import { destr } from 'destr'

const props = defineProps<{
  data?: any
}>()

const viewport = useViewport()

const { t, n, locale } = useI18n()
const localePath = useLocalePath()

const host = await useGetHost('host')

const headers: any = { domain: host, locale: locale.value }

const url = useRequestURL()

const sitedata: any = useSiteData()

const cartKey = sitedata.value?.site_type === 4 ? 'cart-shop' : 'cart'

const cartStorageKey = computed(() => locale.value ? `${cartKey}-${locale.value}` : cartKey)

const cartStorage: any = import.meta.client ? destr(localStorage.getItem(cartStorageKey.value)) : {}

const cart: any = ref(cartStorage ?? {})

const isOpen = ref(false)

const selected = ref<any>({})

const addCartLoading = ref(false)

const times = computed(() => sitedata.value?.payment_period ?? 1)

async function saveCart() {
  if (cart.value?.items?.length === 0 && !cart.value?.id)
    return false

  const data: any = await $fetch('/api/cart', {
    method: 'post',
    headers,
    body: cart.value,
  })

  if (data)
    _set(cart.value, 'id', data)

  return false
}

async function saveCartShop() {
  cart.value?.items_grouped?.forEach(async (group: any, index: any) => {
    let shopCart = _omit(cart.value, ['items_grouped'])

    shopCart = Object.assign(shopCart, group)

    const data: any = await $fetch('/api/cart', {
      method: 'post',
      headers,
      body: shopCart,
    })

    if (!group?.id && data) {
      _set(cart.value, `items_grouped.${index}.id`, data)

      if (import.meta.client)
        localStorage.setItem(cartStorageKey.value, JSON.stringify(cart.value))
    }
  })
}

async function addCart(item: any) {
  addCartLoading.value = true

  // check item in cart
  const index = _findIndex(cart.value?.items, { id: item?.id })

  if (index > -1) {
    addCartLoading.value = false
    toastAdd({ title: t('added_to_cart', { name: item?.name ?? '' }) })
    return false
  }

  const cartItem: any = _pick(item, ['id', 'name', 'slug', 'image', 'unit', 'unit_price', 'vat'])

  if (props?.data?.order_type)
    _set(cartItem, 'order_type', props?.data?.order_type)

  _set(cartItem, 'times', times.value ?? 1)

  _set(cartItem, 'qty', 1)

  const items = _uniqBy(_concat(cart.value?.items ?? [], cartItem), 'id')

  cart.value.items = items

  await saveCart()

  if (import.meta.client)
    localStorage.setItem(cartStorageKey.value, JSON.stringify(cart.value))

  addCartLoading.value = false

  toastAdd({ title: t('added_to_cart', { name: cartItem?.name ?? '' }) })

  return false
}

async function addCartShop(item: any) {
  addCartLoading.value = true

  const indexGroup = _findIndex(cart.value?.items_grouped, { shop_id: item?.shop_id })

  const items = _get(cart.value?.items_grouped, `${indexGroup}.items`)

  const index = _findIndex(items, { id: item?.id })

  if (index > -1) {
    addCartLoading.value = false
    toastAdd({ title: t('added_to_cart', { name: item?.name ?? '' }) })
    return false
  }

  const cartItem: any = _pick(item, ['id', 'name', 'slug', 'image', 'unit', 'unit_price', 'vat'])

  _set(cartItem, 'times', times.value ?? 1)

  _set(cartItem, 'qty', 1)

  const itemsGroup = _uniqBy(_concat(items ?? [], cartItem), 'id')

  if (indexGroup > -1) {
    _set(cart.value, `items_grouped.${indexGroup}.items`, itemsGroup)
  }
  else {
    if (cart.value?.items_grouped?.length) {
      cart.value.items_grouped = [
        ...cart.value.items_grouped,
        ...[
          {
            items: itemsGroup,
            shop_id: item?.shop_id,
            shop_name: item?.shop_name,
            shop_slug: item?.shop_slug,
          },
        ],
      ]
    }
    else {
      _set(cart.value, 'items_grouped.0', {
        items: itemsGroup,
        shop_id: item?.shop_id,
        shop_name: item?.shop_name,
        shop_slug: item?.shop_slug,
      })
    }
  }

  await saveCartShop()

  if (import.meta.client)
    localStorage.setItem(cartStorageKey.value, JSON.stringify(cart.value))

  addCartLoading.value = false

  toastAdd({ title: t('added_to_cart', { name: cartItem?.name ?? '' }) })

  return false
}

async function onAddToCart(item: any) {
  if (sitedata.value?.site_type === 3)
    await navigateTo(item?.slug ? localePath(`/${item?.slug}`) : localePath('/'))
  else if (sitedata.value?.site_type === 4)
    addCartShop(item)
  else
    addCart(item)
}

async function onSelectCategory(option: any) {
  isOpen.value = false
  await navigateTo(option.slug ? localePath(`/${option.slug}`) : localePath('/'))
}
</script>

<template>
  <div v-if="props?.data?.product_category?.length" class="mx-auto max-w-screen-xl relative block lg:hidden text-center px-2 xl:px-0 mb-8">
    <UButton
      block
      :label="t('category')"
      color="gray"
      size="xl"
      @click="isOpen = true"
    >
      <template #trailing>
        <UIcon name="i-heroicons-arrow-right-20-solid" />
      </template>
    </UButton>

    <UModal v-model="isOpen">
      <UCommandPalette
        v-model="selected"
        nullable
        :autoselect="false"
        :groups="[{ key: 'people', commands: props?.data?.product_category }]"
        :empty-state="{ icon: 'i-heroicons-magnifying-glass-20-solid', label: t('no_items'), queryLabel: t('no_items_try') }"
        :placeholder="t('type_something')"
        :ui="{
          input: {
            size: 'sm:text-xl',
          },
          emptyState: {
            label: 'text-xl',
            queryLabel: 'text-xl',
          },
          group: {
            label: 'text-xl',
            container: 'text-xl',
            command: {
              base: 'py-2.5',
            },
          },
        }"
        @update:model-value="onSelectCategory"
      />
    </UModal>
  </div>

  <div class="mx-auto max-w-screen-xl relative flex gap-5 flex-col md:flex-row px-2 xl:px-0">
    <div v-if="props?.data?.product_category?.length" class="w-full lg:w-68 md:w-60 shrink-0 max-h-64 lg:max-h-none overflow-y-auto hidden lg:block">
      <h4 class="px-4 text-sm font-semibold uppercase mb-4">
        {{ t('category') }}
      </h4>
      <ul class="mb-8">
        <li
          v-for="(item, index) in props?.data?.product_category"
          :key="`mVmLaV${index}`"
        >
          <NuxtLinkLocale :to="item?.slug ? `/${item?.slug}` : '/'" :hreflang="locale" class="px-4 py-2 flex items-center justify-between hover:bg-gray-100 rounded-xl ">
            <span class="text-secondary text-base">{{ item?.label }}</span>
            <span v-if="parseInt(item?.count)" class="flex justify-center items-center h-6 w-6 text-xs text-secondary bg-gray-50 rounded">{{ item?.count }}</span>
          </NuxtLinkLocale>
        </li>
      </ul>

      <div v-if="viewport.isGreaterThan('tablet')">
        <SupportSocial />

        <QrCode
          v-if="url.href"
          :data="url.href"
          img-class="md:h-52 md:w-52"
          caption-class="w-full mx-auto"
        />
      </div>
    </div>

    <div v-if="props?.data?.items?.length" class="w-full grow-2">
      <div class="grid grid-cols-2 gap-2 md:grid-cols-3 lg:grid-cols-3 sm:gap-8 px-2 xl:px-0">
        <article
          v-for="(item, index) in props?.data?.items"
          :key="`uYYBka${index}`"
          class="relative flex flex-col overflow-hidden rounded-lg border"
        >
          <div class="aspect-square overflow-hidden cursor-pointer" @click="navigateTo(item?.slug ? localePath(`/${item?.slug}`) : localePath('/'))">
            <NuxtImg
              v-if="item?.image && checkImage(_get(item, 'image.0.thumbnail') ?? _get(item, 'image.0.link'))"
              loading="lazy"
              :src="_get(item, 'image.0.thumbnail') ?? _get(item, 'image.0.link')"
              alt=""
              class="h-full w-full object-cover transition-all duration-300 hover:scale-110"
            />
          </div>
          <div class="my-4 mx-auto flex w-11/12 flex-col items-start justify-between">
            <div v-if="item?.unit_price" class="mb-2 flex">
              <p class="mr-3 text-lg font-semibold">
                {{ n((item?.unit_price ?? 0) * 1, 'currency', locale) }}
              </p>
            </div>
            <h3 class="mb-2 text-lg text-gray-600 hover:text-gray-800 cursor-pointer" @click="navigateTo(item?.slug ? localePath(`/${item?.slug}`) : localePath('/'))">
              {{ item?.name }}
            </h3>
          </div>

          <div v-if="sitedata?.hascart" class="mx-auto mb-2 w-11/12">
            <div v-if="item?.qty === 0" class="text-center text-orange-600">
              {{ sitedata?.site_type === 3 ? t('out_of_stock_room') : t('out_of_stock') }}
            </div>
            <UButton
              v-else
              :disabled="addCartLoading"
              icon="i-heroicons-plus"
              size="xl"
              color="gray"
              variant="solid"
              :label="sitedata?.site_type === 3 ? t('book_now') : t('add_to_cart')"
              :trailing="false"
              padded
              truncate
              :ui="{
                base: 'mx-auto flex h-10 w-full xl:px-12 xl:py-3 items-center justify-center',
                font: 'uppercase',
              }"
              @click="onAddToCart(item)"
            />
          </div>
        </article>
      </div>

      <slot name="more" />
    </div>

    <div v-else class="mx-auto text-center text-lg">
      {{ t('search_no_items') }}
    </div>

    <div v-if="viewport.isLessOrEquals('tablet')">
      <SupportSocial />

      <QrCode
        v-if="url.href"
        :data="url.href"
        img-class="md:h-52 md:w-52"
        caption-class="w-full mx-auto"
      />
    </div>
  </div>
</template>
