<script setup lang="ts">
const localePath = useLocalePath()

const sitedata: any = useSiteData()
</script>

<template>
  <div class="mx-auto max-w-screen-2xl px-2 pt-10 mb-10 sm:mb-20 overflow-hidden">
    <div class="container max-w-xxxl mx-auto px-5 lg:px-8">
      <div class="flex text-center items-center relative max-w-full mx-auto">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-52 absolute lg:block hidden right-[80%] top-1/2 transform -translate-y-1/2" fill="none" viewBox="0 0 296 136"><path fill="#FF6100" d="M158.63 81.37a32 32 0 1 1-45.26 45.26 32 32 0 0 1 45.26-45.26Z" /><path fill="#FFB629" d="M210.14 29.86a20 20 0 1 1-28.28 28.28 20 20 0 0 1 28.28-28.28Z" /><path fill="#9356D0" d="M293.66 58.34a8 8 0 1 1-11.32 11.32 8 8 0 0 1 11.32-11.32Z" /><path fill="#78BB07" d="M252.49 83.51a12 12 0 1 1-16.97 16.97 12 12 0 0 1 16.97-16.97Z" /><path fill="#09F" d="M75.11 12.89A44 44 0 1 1 12.9 75.1a44 44 0 0 1 62.2-62.2Z" /></svg>
        <div class="w-full grow-1 lg:shrink-0 lg:px-6">
          <p class="mx-auto -mt-4 text-lg tracking-tight text-slate-700 sm:mt-6">
            <span class="border-b border-dotted border-slate-300">
              {{ _get(sitedata?.home_text, 0) }}
            </span>
          </p>

          <div class="mx-auto font-display text-4xl sm:text-6xl font-bold tracking-tight text-slate-900">
            <!-- <div class="block">
              <div
                class="relative whitespace-nowrap"
                :style="`color: ${sitedata?.primary_color ?? '#00701a'}`"
              >
                <svg aria-hidden="true" viewBox="0 0 418 42" class="absolute top-2/3 left-0 h-[0.58em] w-full" :style="`fill: ${sitedata?.primary_color ?? '#00701a'}; opacity: 0.5;`" preserveAspectRatio="none"><path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z" /></svg>
                <h1 class="relative">
                  {{ _get(sitedata?.home_text, 1) }}
                </h1>
              </div>
            </div> -->
            <h1
              class="block whitespace-nowrap mb-2"
              :style="`color: ${sitedata?.primary_color ?? '#00701a'}`"
            >
              {{ _get(sitedata?.home_text, 1) }}
            </h1>
            <h2 class="block">
              {{ _get(sitedata?.home_text, 2) }}
            </h2>
          </div>

          <h3 class="line-height-2xl lg:line-height-3xl text-xl tracking-tight color-navy-700 mt-6 mb-8 mx-auto">
            {{ _get(sitedata?.home_text, 3) }}
          </h3>

          <div class="mt-6 flex flex-col justify-center gap-y-5 sm:flex-row sm:gap-y-0 sm:gap-x-6">
            <UButton
              color="black"
              :label="_get(sitedata?.home_text, 4)"
              :to="checkHttp(_get(sitedata?.home_text, 5)) ? _get(sitedata?.home_text, 5) : localePath(_get(sitedata?.home_text, 5))"
              icon="i-heroicons-cube-solid"
              size="lg"
              variant="solid"
              class="md:px-8"
              :ui="{ rounded: 'rounded-full' }"
            />
            <UButton
              color="white"
              :label="_get(sitedata?.home_text, 6)"
              :to="checkHttp(_get(sitedata?.home_text, 7)) ? _get(sitedata?.home_text, 7) : localePath(_get(sitedata?.home_text, 7))"
              icon="i-heroicons-envelope-20-solid"
              size="lg"
              variant="solid"
              class="md:px-8"
              :ui="{ rounded: 'rounded-full' }"
            />
          </div>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" class="w-52 absolute lg:block hidden left-[80%] top-1/2 transform -translate-y-1/2" fill="none" viewBox="0 0 296 136"><path d="M137.37 54.63a32 32 0 1 1 45.26-45.26 32 32 0 0 1-45.26 45.26Z" fill="#FF6100" /><path d="M85.86 106.14a20 20 0 1 1 28.28-28.28 20 20 0 0 1-28.28 28.28Z" fill="#FFB629" /><path d="M2.34 77.66a8 8 0 1 1 11.32-11.32A8 8 0 0 1 2.34 77.66Z" fill="#9356D0" /><path d="M43.51 52.49A12 12 0 1 1 60.48 35.5 12 12 0 0 1 43.51 52.5Z" fill="#78BB07" /><path d="M220.89 123.11A44 44 0 1 1 283.1 60.9a44 44 0 0 1-62.22 62.22Z" fill="#09F" /></svg>
      </div>
    </div>
  </div>
</template>
