<script setup lang="ts">
definePageMeta({
  layout: false,
})

const { locale } = useI18n()
const localePath = useLocalePath()

const hostPort = await useGetHost('hostport')
const host = _get(hostPort, 0)
const port = _get(hostPort, 1)

const { data }: any = await useFetch('/api/homepage', {
  headers: { domain: host, locale: locale.value },
})

const sitedata: any = useSiteData()

const uri = port ? `http://${host}:${port}${localePath('/')}` : `http://${host}${localePath('/')}`

useHead({
  title: sitedata.value?.meta_title || sitedata.value?.name,
  meta: [
    { name: 'description', content: sitedata.value?.meta_description || sitedata.value?.name },
    { property: 'og:title', content: sitedata.value?.meta_title || sitedata.value?.name },
    { property: 'og:description', content: sitedata.value?.meta_description || sitedata.value?.name },
    { property: 'og:image', content: sitedata.value?.meta_image || sitedata.value?.logo },
    // { property: 'og:image:type', content: 'image/png' },
    // { property: 'og:image:width', content: '300' },
    // { property: 'og:image:height', content: '300' },
    // { property: 'og:image:alt', content: host },
    { property: 'og:type', content: 'website' },
    { property: 'og:site_name', content: sitedata.value?.meta_title || sitedata.value?.name },
    { property: 'og:url', content: uri },
    // { name: 'twitter:card', content: 'summary_large_image' },
    // { name: 'twitter:site', content: `@${host}` },
    // { name: 'twitter:title', content: sitedata.value?.meta_title || sitedata.value?.name },
    // { name: 'twitter:description', content: sitedata.value?.meta_description || sitedata.value?.name },
    // { name: 'twitter:image', content: sitedata.value?.meta_image || sitedata.value?.logo },
  ],
  link: [
    {
      hid: 'icon',
      rel: 'icon',
      type: 'image/x-icon',
      href: sitedata.value?.favicon ? sitedata.value?.favicon : null,
    },
  ],
})
</script>

<template>
  <div v-if="sitedata?.layout === 'singlepage'">
    <NuxtLayout name="singlepage">
      <SinglePage :data="data" />
    </NuxtLayout>
  </div>

  <div v-else>
    <NuxtLayout name="default">
      <SecondHero v-if="sitedata?.hero === 2" />

      <DefaultHero v-else />

      <SearchProduct />

      <div class="pt-5 lg:pt-15">
        <ProductIndex :data="data">
          <template #more>
            <div class="w-full text-center my-10">
              <UButton
                color="gray"
                :label="_get(sitedata?.home_text, 8)"
                :to="checkHttp(_get(sitedata?.home_text, 9)) ? _get(sitedata?.home_text, 9) : localePath(_get(sitedata?.home_text, 9))"
                size="xl"
                variant="solid"
                :ui="{ rounded: 'rounded-full' }"
              >
                <template #trailing>
                  <UIcon name="i-heroicons-chevron-double-right-20-solid" />
                </template>
              </UButton>
            </div>
          </template>
        </ProductIndex>
      </div>
    </NuxtLayout>
  </div>
</template>
